import React from 'react';

import '../../../assets/style/pages/site/subpage/index.scss';
import { UISiteFooter } from '../../../components/uisite/footer';
import { UISiteHeaderMenu } from '../../../components/uisite/header/menu';

import jogador from '../../../assets/image/football.png';

export const PageReadMore = (props) => {
    return (
        <div className="page-readmore">
            <header>
                <UISiteHeaderMenu {...props} />
            </header>
            <section>
                <div className="content">
                    <h1>
                        Sobre nós
                    </h1>
                    <div>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste iusto maiores reiciendis, magnam consequatur debitis quibusdam, tenetur harum minus officiis ullam voluptatibus odit vero modi minima veniam, id enim unde.
                    </div>
                    <h1>
                        Nosso diferencial
                    </h1>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam nam alias non ab dolor molestias nemo vero, quas reprehenderit iusto, corrupti quaerat impedit culpa rem doloremque ex facere, quod vel!
                        </div>
                        <div className="p-col-12 p-md-6">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi sequi et iusto eaque rerum porro alias consequuntur incidunt, perferendis distinctio dignissimos ad. Voluptate mollitia quae aliquid totam asperiores! Nesciunt, tenetur.
                        </div>
                    </div>
                    <h1>
                        Nossos produtos
                    </h1>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam nam alias non ab dolor molestias nemo vero, quas reprehenderit iusto, corrupti quaerat impedit culpa rem doloremque ex facere, quod vel!
                            </p>
                            <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam nam alias non ab dolor molestias nemo vero, quas reprehenderit iusto, corrupti quaerat impedit culpa rem doloremque ex facere, quod vel!
                            </p>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <img style={{width: '100%', display:'block', filter:'grayscale(0.7)'}} src={jogador} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <UISiteFooter {...props} />
            </footer>
        </div>
    )
}