import React from "react";


import '../../../../assets/style/components/dashboard/card/basic/index.scss';
;
export const DashboardCardBasic = (props) => {

    const style = {
        '--background':'#111',
        '--title':'#FFF',
        '--value':'#FFF',
        ...props?.style
    }

    return (
        <div style={style} className="dashboard-cardbasic">
            <div className="title">
                {props?.data?.title}
            </div>
            <div className="text-value">
                {props?.data?.value}
            </div>
        </div>
    )
}