import React, { useRef, useEffect, useState, useMemo } from "react";

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DashboardCardBasic } from "../../components/dashboard/card/basic";


import { Divider } from 'primereact/divider';
 

export const PageUser = (props) => {

    const [data, setData ] = useState(null);
    const chartRef = useRef();
    const chartDraw = useRef();

    const colors = useMemo(()=>[
        '#cfe2ff',
        '#d1e7dd',
        '#f8d7da',
        '#fff3cd'
    ], []);

    useEffect(()=>{


        Chart.register(ChartDataLabels);

        const config =  {
            type: 'bar',
            data: {
                labels: ['Predições', 'Acertos', 'Erros', 'Acurácia'],
                datasets: [
                {
                    label: 'Total',
                    data: [data?.predicoes, data?.acertos, data?.erros, data?.acuracia],
                    backgroundColor: colors,
                    borderWidth: 0
                }
            ]
            },
            options: {
                aspectRatio: true,
                responsive: true,
                events: [],
                plugins:{
                    datalabels: {
                        color: '#FFF',
                        anchor: 'end',
                        offset: -20,
                        align:'bottom'
                    },
                    legend:{
                        display: false
                    },
                    tooltip:{
                        enable: false
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        }

        if(chartDraw.current)
            chartDraw.current.destroy();
    
        chartDraw.current = new Chart(chartRef.current.getContext('2d'), config);
    },[data, colors]);

    useEffect(()=>{
        setData({
            predicoes: 35498,
            acertos: 27883,
            erros: 7615,
            acuracia: 79
        })
    },[])

    return (
        <div className="page-user">
            <section>
                <div className="content">
                    <Divider align="left" type="dashed">
                        <h1>
                            Dashboard
                        </h1>
                    </Divider>
                    <div className="dash-resume">
                        <div className="graphic">
                            <canvas ref={chartRef} style={{height:'250px', maxHeight: '250px'}}  />
                        </div>
                        <div>
                            <DashboardCardBasic data={{
                                title: 'Predições',
                                value: data?.predicoes
                            }} 
                            style={{
                                '--background':colors[0],
                                '--title':'#084298',
                                '--value':'#084298',
                            }}
                            />
                        </div>
                        <div>
                            <DashboardCardBasic data={{
                                title: 'Acertos',
                                value: data?.acertos
                            }} 
                            style={{
                                '--background':colors[1],
                                '--title':'#0f5132',
                                '--value':'#0f5132',
                            }}
                            />
                        </div>
                        <div>
                            <DashboardCardBasic data={{
                                title: 'Erros',
                                value: data?.erros
                            }} 
                            style={{
                                '--background':colors[2],
                                '--title':'#842029',
                                '--value':'#842029',
                            }}
                            />
                        </div>
                        <div>
                            <DashboardCardBasic data={{
                                title: 'Acurácia',
                                value: `${data?.acuracia}%`
                            }} 
                            style={{
                                '--background':colors[3],
                                '--title':'#664d03',
                                '--value':'#664d03',
                            }}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}