import React from "react";

import '../../../assets/style/components/ui/loading/index.scss';
import { useApp } from "../../../services/provider";

export const UILoading = (props) => {

    const { loading } = useApp();

    return (
        <div style={{display: loading ? 'flex' : 'none'}} className="ui-loading">
            <div className="loading">
                <div style={{'--delay':'0.2s'}}></div>
                <div style={{'--delay':'0.4s'}}></div>
                <div style={{'--delay':'0s'}}></div>
                <div style={{'--delay':'0.6s'}}></div>
                <div style={{'--delay':'0.8s'}}></div>
            </div>
        </div>
    )
}