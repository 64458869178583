import React from "react";

import { Menubar } from 'primereact/menubar';
import { UILogo } from "../logo";

import { Avatar } from 'primereact/avatar';


export const HeaderBar = (props) => {

    const items = [
        {label:'Dashboard', command:()=> props.history.push('/')},
        {label:'Histórico'},
        {label:'Sair', command:()=> props.history.push('/') }
    ]

    return (
        <div className="ui-headerbar">
            <Menubar 
            start={
                <div className="top">
                    <div className="logo">
                        <UILogo {...props} />
                    </div>
                </div>
            }
            end={
                <div>
                    <span style={{color:'#FFF'}} className="p-mr-2">
                        Usuário
                    </span>
                    <Avatar label="A" size="large" shape="circle">

                    </Avatar>
                </div>
            }
            model={items}/>
        </div>
    )
}