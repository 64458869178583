import React, {useEffect, useState} from 'react';

export const CardCounter = (props) => {

    const [result, setResult] = useState(props.start);


    useEffect(()=> {

        const timer = Math.round(((props.end - props.start) / props.time));

        if(result < props.end){
            setResult(result + timer);
        }else if(result !== props.end){
            setResult(props.end);
        }

    }, [result, props]);

    return (
        <div className="card-counter">
            {props?.prefix}{result}{props?.suffix}
        </div>
    )
}