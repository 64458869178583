import React from 'react';
import { UILogo } from '../../../ui/logo';

export const UISiteHeaderMenu = (props) => {
    return (
        <div className="top nologin">
            <div className="logo">
                <UILogo {...props} />
            </div>
            <div className="menu">
                <ul>
                    <li onClick={()=>{
                        props.history.push('/')
                    }}>
                        Home
                    </li>
                    <li onClick={()=>{
                        props.history.push('/readmore')
                    }}>
                        Sobre nós
                    </li>
                    <li onClick={()=>{
                        props.history.push('/signup')
                    }}>
                        Cadastro
                    </li>
                    <li onClick={()=>{
                        props.history.push('/login')
                    }}>
                        Entrar
                    </li>
                    <li onClick={()=>{
                        props.history.push('/faq')
                    }}>
                        FAQ
                    </li>
                    <li onClick={()=>{
                        props.history.push('/contact')
                    }}>
                        Contato
                    </li>
                </ul>
            </div>
        </div>
    )
}