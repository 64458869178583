import React, { useEffect } from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import { HeaderBar } from '../../components/ui/headerbar';


import { PageUser } from '../user';

import '../../assets/style/pages/user/index.scss';

export const PageBase = (props) => {

    useEffect(()=>{

        console.log('BASE');

    }, []);

    return (
        <div className="page-base">
            <header className="area-header">
                <HeaderBar {...props} />
            </header>
            <section>
                <BrowserRouter key={`${props.location.pathname.replace(/\//gi,'_')}`} basename="/">
                    <Switch>
                         <Route exact path="/user" component={(p)=><PageUser {...p} {...props} />} />
                        {/*<Route exact path="/:client/user/sigup" component={(p)=><PageSigup {...p} {...props} />} />
                        <Route exact path="/:client" component={(p)=> <PageStore {...p} {...props} />} />
                        <RoutePritvate extact path="/:client/user/address" component={(p)=> <PageUserAddress {...p} {...props} /> } />
                        <RoutePritvate extact path="/:client/user" component={(p)=> <PageUserProfile {...p} {...props} /> } /> */}
                        <Route path="*" component={()=><>Base - {props.match.params.area} - {props.location.pathname}</>} />
                    </Switch>
                </BrowserRouter>
            </section>
        </div>
    )
}