import React from 'react';

import { Icon } from 'react-icons-kit';
import {twitter} from 'react-icons-kit/icomoon/twitter';
import {facebook2} from 'react-icons-kit/icomoon/facebook2';
import {instagram} from 'react-icons-kit/icomoon/instagram';
import {telegram} from 'react-icons-kit/icomoon/telegram';

export const UISiteFooter = (props) => {
    return (
        <>
            <div className="content">
                    <div>

                    </div>
                    <div className="social">
                        <ul>
                            <li>
                                <Icon icon={twitter} />
                            </li>
                            <li>
                                <Icon icon={facebook2} />
                            </li>
                            <li>
                                <Icon icon={instagram} />
                            </li>
                            <li>
                                <Icon icon={telegram} />
                            </li>
                        </ul>
                    </div>
                    <div className="copy">
                        &copy; 2021 BeltDCI, todos direitos reservados
                    </div>
                    <div>
                        <ul>
                            <li onClick={()=> {
                                props.history.push('/readmore');
                            }}>Sobre Nós</li>
                            <li onClick={()=> {
                                props.history.push('/faq');
                            }}>FAQ</li>
                            <li onClick={()=> {
                                props.history.push('/contact');
                            }}>Contato</li>
                        </ul>
                    </div>
                </div>
        </>
    )
}