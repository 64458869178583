import React from 'react';

import escanteio from '../../assets/image/icon/escanteio.png';
import gol from '../../assets/image/icon/gol.png';
import funil from '../../assets/image/icon/funil.png';

import { CardPrice } from '../../components/card/price';
import { CardCounter } from '../../components/card/counter';
import { UISiteHeaderHome } from '../../components/uisite/header/home';
import { UISiteFooter } from '../../components/uisite/footer';

import '../../assets/style/pages/site/index.scss';

export const PageSite = (props) => {

    return (
        <div className="page-site">
            <header>
                <UISiteHeaderHome {...props} />
            </header>
            <div className="banner">
                <div className="content">
                    <h1 className="title">
                        Sistema de Tips de Futebol
                    </h1>
                    <div className="text">
                        Receba diariamente Tips de jogos de futebol com alta assertividade e lucratividade
                    </div>
                    <div className="buttons">
                        <div onClick={()=> {
                            props.history.push('/readmore');
                        }} className="read-more">Saiba mais</div>
                        <div onClick={()=> {
                            props.history.push('/contact');
                        }} className="contact">Entre em contato</div>
                    </div>
                </div>
            </div>
            <section>
                <div className="content">
                    <div className="data-info">
                        <h1 className="title">
                            Predições com base em Machine learning
                        </h1>
                        <div>
                            O sistema da <strong>BetDCI</strong> foi desenvolvido por um time de cientistas de dados e analistas de partidas de futebol. Com base em estatísticas, matemática e o conhecimento dos nossos analistas, desenvolvemos um sistema capaz de realizar predições com alta assetividade.
                        </div>
                        <div className="data-text">
                            <div>
                                <h1>
                                    <CardCounter start={0} end={17749} time={50} prefix={''} suffix={''} />
                                </h1>
                                <div>
                                    Alertas de Predições
                                </div>
                            </div>
                            <div>
                                <h1>
                                    <CardCounter start={0} end={12952} time={50} prefix={''} suffix={''} />
                                </h1>
                                <div>
                                    Acertos
                                </div>
                            </div>
                            <div>
                                <h1>
                                    <CardCounter start={0} end={4797} time={50} prefix={''} suffix={''} />
                                </h1>
                                <div>
                                    Erros
                                </div>
                            </div>
                            <div>
                                <h1>
                                    <CardCounter start={0} end={73} time={100} prefix={''} suffix={'%'} />
                                </h1>
                                <div>
                                    Assertividade
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="data-receive">
                        <h1 className="title">
                            O que você irá receber:
                        </h1>
                        <div className="data-text">
                            <div>
                                <div className="icon">
                                    <img src={escanteio} alt="" />
                                </div>
                                <h1>
                                    Escanteios FT-Live
                                </h1>
                                <div>
                                    Receba diariamente em média 20 tips de escateios FT Live com assetividade de 75% e lucratividade de 25%
                                </div>
                            </div>
                            <div>
                                <div className="icon">
                                    <img src={gol} alt="" />
                                </div>
                                <h1>
                                    Gols HT/FT-Live
                                </h1>
                                <div>
                                    Receba diariamente em média 5 tips de gols HT e FT Live com assetividade de 75% e lucratividade de 25%
                                </div>
                            </div>
                            <div>
                                <div className="icon">
                                    <img src={funil} alt="" />
                                </div>
                                <h1>
                                    Estratégia do Funil
                                </h1>
                                <div>
                                    Receba alertas de escateios diarios com base na estratégia do funil
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="separator"></div>

                    <div className="data-prices">
                        <h1 className="title">
                            Plano(s)
                        </h1>
                        <div className="text">
                            Tenha acesso à todos os sinais disponíveis no bot <strong>BetDCI</strong>
                        </div>
                        <div className="card-list">
                            <div className="card">
                                <CardPrice />
                            </div>
                        </div>
                    </div>

                    <div className="box-read-more">
                        <div className="content">
                            <div className="text">
                                Ainda está em dúvida?
                            </div>
                            <div className="button">
                                <div onClick={()=>{
                                    props.history.push('/contact');
                                }} className="read-more">
                                    Entre em contato
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <footer>
                <UISiteFooter {...props} />
            </footer>
        </div>
    )
}