import React, { useState, createContext, useContext } from 'react';

export const AppContext= createContext();

export const AppProvider = (props) => {

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    return (
        <AppContext.Provider value = {
            {
                user,
                setUser,
                loading,
                setLoading
            }
        }>
            {props.children}
        </AppContext.Provider>
    )
}

export const useApp = () => {
    const context = useContext(AppContext);
    const {
        user,
        setUser,
        loading,
        setLoading
    } = context;

    return {
        user,
        setUser,
        loading,
        setLoading
    }
}