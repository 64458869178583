import React  from 'react';

import { UISiteFooter } from '../../../components/uisite/footer';
import { UISiteHeaderMenu } from '../../../components/uisite/header/menu';

import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
 

import '../../../assets/style/pages/site/subpage/index.scss';

export const PageContact = (props) => {


    const defaultValues = {
        name: '',
        email: '',
        phone:'',
        message:''
    }
    
    const form = useForm({ defaultValues });
    const control =  form.control;
    const errors  = form.formState.errors ;
    const handleSubmit = form.handleSubmit;

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = (_data) =>{
        //setIsLoad(true);
    }

    return (
        <div className="page-contact">
            <header>
                <UISiteHeaderMenu {...props} />
            </header>
            <section>
                <div className="content">
                    <div className="p-grid">
                        <div className="p-12 p-md-6">
                            <h1>
                                Contato
                            </h1>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="p-grid p-mt-2 p-mb-2">
                                    <div className="p-col-12">
                                        <div className="p-field">
                                            <span className="p-float-label">
                                                <Controller name="name" control={control} rules={{ required: 'Campo obrigatório', maxLength:11, minLength:11 }} render={({ field, fieldState }) => (
                                                    <InputText type="text" style={{width:'100%'}} id={field.name} {...field} />
                                                )} />
                                                <label htmlFor="name">Nome *</label>
                                            </span>
                                            {getFormErrorMessage('name')}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-field">
                                            <span className="p-float-label">
                                                <Controller name="email" control={control} rules={{ required: 'Campo obrigatório', maxLength:11, minLength:11 }} render={({ field, fieldState }) => (
                                                    <InputText type="email" style={{width:'100%'}} id={field.name} {...field} />
                                                )} />
                                                <label htmlFor="email">Email *</label>
                                            </span>
                                            {getFormErrorMessage('email')}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-field">
                                            <span className="p-float-label">
                                                <Controller name="phone" control={control} rules={{ required: 'Campo obrigatório', maxLength:11, minLength:11 }} render={({ field, fieldState }) => (
                                                    <InputText type="tel" style={{width:'100%'}} id={field.name} {...field} />
                                                )} />
                                                <label htmlFor="phone">Telefone *</label>
                                            </span>
                                            {getFormErrorMessage('phone')}
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-field">
                                            <span className="p-float-label">
                                                <Controller name="message" control={control} rules={{ required: 'Campo obrigatório', maxLength:11, minLength:11 }} render={({ field, fieldState }) => (
                                                    <InputTextarea style={{width:'100%'}} autoResize id={field.name} {...field} />
                                                )} />
                                                <label htmlFor="message">Mensagem *</label>
                                            </span>
                                            {getFormErrorMessage('message')}
                                        </div>
                                    </div>
                                    <div style={{textAlign:'center'}} className="p-col-12">
                                        <Button type="submit" style={{margin:'0 auto'}} label="Enviar" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="p-12 p-md-offset-1 p-md-5">
                            <h1>
                                Outros contatos
                            </h1>
                            <div>
                                Responderemos em até 72 horas.
                            </div>
                            <ul>
                                <li>
                                    E-mail
                                </li>
                                <li>
                                    Facebook
                                </li>
                                <li>
                                    Instagram
                                </li>
                                <li>
                                    Twitter
                                </li>
                                <li>
                                    Telegram
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <UISiteFooter {...props} />
            </footer>
        </div>
    )
}