import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { PageBase } from '../../../pages/base';
import { PageSite } from '../../../pages/site';
import { PageLogin } from '../../../pages/site/login';
import { PageReadMore } from '../../../pages/site/readmore'
import { PageContact } from '../../../pages/site/contact';
import { PageSigup } from '../../../pages/site/signup';
import { PageFAQ } from '../../../pages/site/faq';

export const MainRouter = (props) => {
    return(
        <BrowserRouter basename='/'>
            <Switch>
                <Route exact path="/login" component={(p)=> <PageLogin {...props} {...p} />} />
                <Route exact path="/signup" component={(p)=> <PageSigup {...props} {...p} />} />
                <Route exact path="/readmore" component={(p)=> <PageReadMore {...props} {...p} />} />
                <Route exact path="/faq" component={(p)=> <PageFAQ {...props} {...p} />} />
                <Route exact path="/contact" component={(p)=> <PageContact {...props} {...p} />} />
                <Route path="/:area" component={(p)=> <PageBase {...props} {...p} />} />
                <Route path="*" component={(p)=> <PageSite {...props} {...p} />} />
            </Switch>
        </BrowserRouter>
    )
}