import React from 'react';
import { UILogo } from '../../../ui/logo';

export const UISiteHeaderHome = (props) => {
    return (
        <div className="top nologin">
            <div className="logo">
                <UILogo {...props} />
            </div>
            <div className="menu">
                <div onClick={()=> {
                    props.history.push('/signup');
                }} className="sign-up">Cadastro</div>
                <div onClick={()=> {
                    props.history.push('/login');
                }} className="sign-in">Entrar</div>
            </div>
        </div>
    )
}