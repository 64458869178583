import React from 'react';
import ReactDOM from 'react-dom';
import { AppProvider } from './services/provider';
import { MainRouter } from './components/navigation/main';

import './assets/style/index.scss';
import { UILoading } from './components/ui/loading';

//import * as serviceWorker from './serviceWorker';

ReactDOM.render(
<AppProvider>
    <MainRouter />
    <UILoading />
</AppProvider>, 
document.getElementById('root'));

//serviceWorker.unregister();
