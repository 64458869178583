import React from 'react';

import { UISiteFooter } from '../../../components/uisite/footer';
import { UISiteHeaderMenu } from '../../../components/uisite/header/menu';

import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
 
import { useApp } from '../../../services/provider';

import '../../../assets/style/pages/site/subpage/index.scss';


export const PageLogin = (props) => {

    const { setLoading } = useApp();

    const defaultValues = {
        email: '',
        password: ''
    }
    
    const form = useForm({ defaultValues });
    const control =  form.control;
    const errors  = form.formState.errors ;
    const handleSubmit = form.handleSubmit;

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = (_data) =>{
        setLoading(true);

        console.log(_data);

        const delay = setTimeout(()=> {
            clearTimeout(delay);
            setLoading(false);
            props.history.push('/user');
        }, 2000);

    }

    return (
        <div className="page-login">
            <header>
                <UISiteHeaderMenu {...props} />
            </header>
            <section>
                <div className="content">
                    <h1>
                        Entrar no sistema
                    </h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="p-grid p-mt-2">
                            <div className="p-col-12">
                                <div className="p-field">
                                    <span className="p-float-label">
                                        <Controller name="email" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                            <InputText type="email" style={{width:'100%'}} id={field.name} {...field} />
                                        )} />
                                        <label htmlFor="email">Email *</label>
                                    </span>
                                    {getFormErrorMessage('email')}
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-field">
                                    <span className="p-float-label">
                                        <Controller name="password" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                            <Password feedback={false} inputStyle={{width:'100%'}} style={{width:'100%'}} id={field.name} {...field} />
                                        )} />
                                        <label htmlFor="password">Senha *</label>
                                    </span>
                                    {getFormErrorMessage('password')}
                                </div>
                            </div>
                            <div style={{textAlign:'center'}} className="p-col-12">
                                <Button type="submit" style={{margin:'0 auto'}} label="Entrar" />
                            </div>
                        </div>
                    </form>
                    
                </div>
            </section>
            <footer>
                <UISiteFooter {...props} />
            </footer>
        </div>
    )
}