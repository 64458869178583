import React from 'react';

import '../../../assets/style/components/card/price/index.scss';

export const CardPrice = (props) => {
    return (
        <div className="card-price">
            <div className="price">
                <span>R$ </span>
                <span>100,00</span>
                <span> / mês</span>
            </div>
            <div className="elements">
                <h4>
                    Incluso:
                </h4>
                <ul>
                    <li>
                        Predições estanteios live
                    </li>
                    <li>
                        Predições gols FT Live
                    </li>
                    <li>
                        Alerta gols HT
                    </li>
                    <li>
                        Alertas estratégia do funil
                    </li>
                </ul>
            </div>
            <div className="action">
                <div className="assign">
                    Assine Já
                </div>
            </div>
        </div>
    )
}